@font-face {
  font-family: Magistral;
  src: url("magistral.c863bf8a.otf");
}

body {
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Magistral, sans-serif;
}

h1 {
  margin: 0;
  font-size: 55px;
}

p {
  font-family: Roboto, sans-serif;
  font-size: 25px;
  line-height: 40px;
}

.light-blue {
  color: #45afc7;
}

.dark-blue {
  color: #001c6d;
}

section {
  height: 100vh;
  padding: 0;
}

section h1, section p {
  text-align: center;
}

section.presentation {
  color: #cdd2db;
  background-color: #1e0022;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

section.presentation h1 {
  width: 40vw;
}

section.presentation img {
  height: 292px;
}

section.introduction {
  color: #050505;
  background-color: #e4e4e4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

section.introduction h1, section.introduction p {
  text-align: center;
  width: 50vw;
}

section.introduction p {
  width: 75vw;
}

section.software-house, section.integration, section.data-science {
  color: #cdd2db;
  background-color: #1e0022;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

section.software-house .content, section.integration .content, section.data-science .content {
  flex-direction: column;
  width: 40vw;
}

section.software-house img, section.integration img, section.data-science img {
  border-radius: 20px;
  width: 40vw;
}

section.integration {
  color: #050505;
  background-color: #e4e4e4;
}

section.contact {
  color: #050505;
  background-color: #e4e4e4;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

section.contact img {
  width: 40vw;
}

section.contact p, section.contact h1 {
  text-align: left;
}

section.integration, section.software-house, section.integration, section.data-science, section.introduction {
  min-height: 832px !important;
}

section.presentation, section.contact {
  min-height: 420px;
}

@media (width <= 900px) {
  h1 {
    font-size: 45px;
  }

  p {
    margin-top: 60px;
    font-size: 20px;
  }

  section.presentation img {
    height: 250px;
  }
}

@media (width <= 750px) {
  h1 {
    font-size: 35px;
  }

  p {
    margin-top: 60px;
    font-size: 20px;
  }

  section p {
    margin-top: 40px;
  }

  section.presentation {
    flex-direction: column;
  }

  section.presentation h1 {
    width: 80%;
    font-size: 45px;
  }

  section.introduction h1 {
    width: 90%;
  }

  section.introduction p {
    width: 90%;
    margin-top: 80px;
  }

  section.software-house, section.integration, section.data-science {
    flex-direction: column-reverse;
  }

  section.software-house .content, section.integration .content, section.data-science .content {
    width: 90%;
  }

  section.software-house img, section.integration img, section.data-science img {
    width: 80%;
    height: 332px;
  }

  section.integration {
    flex-direction: column;
  }
}

@media (width <= 550px) {
  p {
    font-size: 16px;
  }

  section.presentation img {
    height: 200px;
  }

  section.software-house, section.integration, section.data-science {
    justify-content: space-between;
  }

  section.software-house p, section.integration p, section.data-science p {
    margin-top: 20px;
  }

  section.software-house img, section.integration img, section.data-science img {
    border-radius: 0;
    width: 100%;
    height: 291.19px;
  }

  section.software-house .content, section.integration .content, section.data-science .content {
    justify-content: center;
    height: 100%;
    display: flex;
  }

  section.contact {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }

  section.contact img {
    width: 210px;
  }
}

@media (width <= 350px) {
  h1 {
    font-size: 25px;
  }

  p {
    font-size: 14px;
  }

  section.presentation h1 {
    font-size: 35px;
  }

  section.presentation img {
    height: 150px;
  }

  section.software-house p, section.integration p, section.data-science p {
    margin-top: 10px;
  }
}

/*# sourceMappingURL=index.c4c153a2.css.map */
