@font-face {
    font-family: Magistral;
    src: url("font/magistral.otf");
}
body{
    font-family: Magistral, sans-serif;
    color: white;
    padding: 0;
    margin: 0;
}
h1{
    font-size: 55px;
    margin: 0;
}
p{
    font-size: 25px;
    font-family: Roboto, sans-serif;
    line-height: 40px;
}
.light-blue{
    color: #45afc7;
}
.dark-blue{
    color: #001c6d;
}
section{
    height: 100vh;

    padding: 0;
    h1, p{
        text-align: center;
    }
    &.presentation{
        background-color: #1e0022;
        color: #cdd2db;

        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        h1{
            width: 40vw;
        }
        img{
            height: 292px;
        }
    }
    &.introduction{
        background-color: #e4e4e4;
        color: #050505;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1,p{
            width: 50vw;
            text-align: center;
        }
        p{
            width: 75vw;
        }
    }
    &.software-house,&.integration,&.data-science{
        background-color: #1e0022;
        color: #cdd2db;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .content{
            width: 40vw;
            flex-direction: column;
        }
        img{
            width: 40vw;
            border-radius: 20px;
        }
    }
    &.integration{
        background-color: #e4e4e4;
        color: #050505;
    }
    &.contact{
        background-color: #e4e4e4;
        color: #050505;

        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;

        img{
            width: 40vw;
        }
        p,h1{
            text-align: left;
        }
    }
    &.integration,&.software-house,&.integration,&.data-science,&.introduction {
        min-height: 832px !important;;
    }
    &.presentation, &.contact {
        min-height: 420px;
    }
}

@media (max-width: 900px){
    h1 {
        font-size: 45px;
    }

    p {
        font-size: 20px;
        margin-top: 60px;
    }

    section {
        &.presentation {
            img {
                height: 250px;
            }
        }
    }
}

@media (max-width: 750px){

    h1 {
        font-size: 35px;
    }

    p {
        font-size: 20px;
        margin-top: 60px;
    }

    section {
        p {
            margin-top: 40px;
        }

        &.presentation {
            flex-direction: column;
            h1 {
                font-size: 45px;
                width: 80%;
            }
        }
        &.introduction {
            h1 {
                width: 90%;
            }
            p {
                width: 90%;
                margin-top: 80px;
            }
        }
        &.software-house,&.integration,&.data-science {
            flex-direction: column-reverse;
            .content {
                width: 90%;
            }
            img {
                width: 80%;
                height: 332px;
            }
        }
        &.integration {
            flex-direction: column;
        }
    }

}

@media (max-width: 550px){
    p {
        font-size: 16px;
    }

    section {
        &.presentation {
            img {
                height: 200px;
            }
        }

        &.software-house,&.integration,&.data-science {
            p {
                margin-top: 20px;
            }
            justify-content: space-between;
            img {
                width: 100%;
                height: 291.19px;
                border-radius: 0;
            }
            .content {
                height: 100%;
                display: flex;
                justify-content: center;
            }
        }

        &.contact {
            flex-direction: column;
            justify-content: center;
            gap: 50px;
            
            img {
                width: 210px;
            }
        }
    }
}

@media (max-width: 350px) {

    h1 {
        font-size: 25px;
    }
    
    p {
        font-size: 14px;
    }

    section {
        &.presentation {
            h1 {
                font-size: 35px;
            }

            img {
                height: 150px;
            }
        }
        &.software-house,&.integration,&.data-science {
            p {
                margin-top: 10px;
            }
        }

    }
}